import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import '../styles/style.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../styles/utilitary.css'
import '../styles/tailwind.min.css'
import videobg from "../images/12sB&W.mp4"
import Slider from "react-slick";
import Draggable, {DraggableCore} from 'react-draggable'; // Both at the same time
import marca1cor from "../images/marcas/marca1.png" // Tell webpack this JS file uses this image
import marca2cor from "../images/marcas/marca2.png" // Tell webpack this JS file uses this image
import marca3cor from "../images/marcas/marca3.png" // Tell webpack this JS file uses this image
import marca4cor from "../images/marcas/marca4.png" // Tell webpack this JS file uses this image
import marca5cor from "../images/marcas/marca5.png" // Tell webpack this JS file uses this image
import marca6cor from "../images/marcas/marca6.png" // Tell webpack this JS file uses this image
import marca7cor from "../images/marcas/marca7.png" // Tell webpack this JS file uses this image
import marca8cor from "../images/marcas/marca8.png" // Tell webpack this JS file uses this image
import marca9cor from "../images/marcas/marca9.png" // Tell webpack this JS file uses this image
import marca10cor from "../images/marcas/marca10.png" // Tell webpack this JS file uses this image
import marca11cor from "../images/marcas/marca11.png" // Tell webpack this JS file uses this image
import marca12cor from "../images/marcas/marca12.png" // Tell webpack this JS file uses this image
import marca13cor from "../images/marcas/marca13.png" // Tell webpack this JS file uses this image
import marca14cor from "../images/marcas/marca14.png" // Tell webpack this JS file uses this image
import marca15cor from "../images/marcas/marca15.png" // Tell webpack this JS file uses this image
import marca16cor from "../images/marcas/marca16.png" // Tell webpack this JS file uses this image
import marca17cor from "../images/marcas/marca17.png" // Tell webpack this JS file uses this image
import marca18cor from "../images/marcas/marca18.png" // Tell webpack this JS file uses this image
import marca19cor from "../images/marcas/marca19.png" // Tell webpack this JS file uses this image
import marca20cor from "../images/marcas/marca20.png" // Tell webpack this JS file uses this image

import marca1 from "../images/marcas/novas/marca1.png" // Tell webpack this JS file uses this image
import marca2 from "../images/marcas/novas/marca2.png" // Tell webpack this JS file uses this image
import marca3 from "../images/marcas/novas/marca3.png" // Tell webpack this JS file uses this image
import marca4 from "../images/marcas/novas/marca4.png" // Tell webpack this JS file uses this image
import marca5 from "../images/marcas/novas/marca5.png" // Tell webpack this JS file uses this image
import marca6 from "../images/marcas/novas/marca6.png" // Tell webpack this JS file uses this image
import marca7 from "../images/marcas/novas/marca7.png" // Tell webpack this JS file uses this image
import marca8 from "../images/marcas/novas/marca8.png" // Tell webpack this JS file uses this image
import marca9 from "../images/marcas/novas/marca9.png" // Tell webpack this JS file uses this image
import marca10 from "../images/marcas/novas/marca10.png" // Tell webpack this JS file uses this image
import marca11 from "../images/marcas/novas/marca11.png" // Tell webpack this JS file uses this image
import marca12 from "../images/marcas/novas/marca12.png" // Tell webpack this JS file uses this image
import marca13 from "../images/marcas/novas/marca13.png" // Tell webpack this JS file uses this image
import marca14 from "../images/marcas/novas/marca14.png" // Tell webpack this JS file uses this image
import marca15 from "../images/marcas/novas/marca15.png" // Tell webpack this JS file uses this image
import marca16 from "../images/marcas/novas/marca16.png" // Tell webpack this JS file uses this image
import marca17 from "../images/marcas/novas/marca17.png" // Tell webpack this JS file uses this image
import marca18 from "../images/marcas/novas/marca18.png" // Tell webpack this JS file uses this image
import marca19 from "../images/marcas/novas/marca19.png" // Tell webpack this JS file uses this image
import marca1branca from "../images/marcas/brancas/marca1branca.png" // Tell webpack this JS file uses this image
import marca2branca from "../images/marcas/brancas/marca2branca.png" // Tell webpack this JS file uses this image
import marca3branca from "../images/marcas/brancas/marca3branca.png" // Tell webpack this JS file uses this image
import marca4branca from "../images/marcas/brancas/marca4branca.png" // Tell webpack this JS file uses this image
import marca5branca from "../images/marcas/brancas/marca5branca.png" // Tell webpack this JS file uses this image
import marca7branca from "../images/marcas/brancas/marca7branca.png" // Tell webpack this JS file uses this image
import marca12branca from "../images/marcas/brancas/marca12branca.png" // Tell webpack this JS file uses this image
import marca18branca from "../images/marcas/brancas/marca18branca.png" // Tell webpack this JS file uses this image
import Layout from "../components/layout"
import SEO from "../components/seo"


var settings = {
    infinite: true,
    autoplay:true,
    speed: 1000,
    autoplaySpeed:2000,
    slidesToShow: 7,
    slidesToScroll: 1,
    arrows:false,
    centerMode:false,
    swipeToSlide: true,
    touchThreshold: 400,
    draggable:true,

  };
  var settingsMobile = {
    infinite: true,
    autoplay:true,
    speed: 800,
    autoplaySpeed:4000,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows:false,
    centerMode:false,
    swipeToSlide: true,
    touchThreshold: 400,
    draggable:true,

  };
if (typeof window !== `undefined`) {
    let vh = window.innerHeight * 0.01;

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit


// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);



// We listen to the resize event
window.addEventListener('resize', () => {
  // We execute the same script as before
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});
}




const IndexPage = () => (
  <Layout>
    
  <div id="section-home">
  
    <div className="wrapper">
    <video className="video-class"  preload='auto'  autoPlay loop playsInline muted>
               <source src={videobg} type="video/mp4"/>
    </video>
    <div className="col">
        <div className="wrapper-2">
          <div className="elemento">

   
          <div className="titulo play-regular">
          <p className="anos play-bold">20 Anos</p><p className="anos-bottom">ao serviço da indústria</p>

          </div>
          <div className="desc play-regular">
          <p> Comércio de ferramentas, lubrificantes e acessórios para a indústria metalomecânica.<br></br><br></br>
    <i>Stock</i> permanente de ferramentas de <br></br> corte para mecânica de precisão.</p>
          </div>
          </div>
        </div>
    </div>
    </div>
  </div>
  
  <div id="section-brands-desktop">
 
 
    
  
      <div className="container2">
     
    <Slider {...settings}>
        <div className="col-2 w-64 mx-4 self-center flex-none">
            <a target="_blank" href="https://www.dormerpramet.com/"><img src={marca2branca} alt="" /></a>
        
        </div>
        <div className="col-3 w-64 mx-4 self-center flex-none">
        <a target="_blank" href="https://www.electrexwelding.com/"> <img   src={marca3branca} alt="" /></a>
        </div>
        <div className="col-4 w-64 mx-4 self-center flex-none">
        
        <a target="_blank" href="https://www.facom.com/pt/">   <img  src={marca4branca} alt="" /></a>
        </div>
        <div className="col-5 w-64 mx-4 self-center flex-none">
        <a target="_blank" href="https://www.guhring.com/">   <img  src={marca5branca} alt="" /></a>
        </div>
        <div className="col-6 w-64 mx-4 self-center flex-none">
        <a target="_blank" href=" https://www.hazet.de/en/">  <img  src={marca6cor} alt="" /></a>
        </div>
        <div className="col-7 w-64 mx-4 self-center flex-none">
        <a target="_blank" href=" https://www.innotool.de/">  <img src={marca7branca} alt="" /></a>
        
        </div>
        <div className=" w-64 mx-4 self-center flex-none">
        <a target="_blank" href=" https://www.iscar.com/">   <img src={marca1branca} alt="" /> </a>
       
   

        </div>
        <div className="col-8 w-64 mx-4 self-center flex-none">
        <a target="_blank" href="https://www.izartool.com/en/">  <img  src={marca8cor} alt="" /> </a>
        </div>
        <div className="col-9 w-64 mx-4 self-center flex-none">
        <a target="_blank" href="https://www.masterprof.pt/pt/">   <img  src={marca9cor} alt="" /></a>
       
        </div>
        <div className="col-10 w-64 mx-4 self-center flex-none">
        <a target="_blank" href="https://www.mitutoyo.com/"><img  src={marca10cor} alt="" /></a>
        </div>
        <div className="col-11 w-64 mx-4 self-center flex-none">
        <a target="_blank" href="https://www.molyslip.com/"> <img  src={marca11cor} alt="" /></a>
        </div>
        
        <div className="col-12 w-64 mx-4 self-center flex-none">
        <a target="_blank" href="https://www.nsk.com/"><img src={marca12branca} alt="" /></a>
        </div>
        <div className="col-13 w-64 mx-4 self-center flex-none">
        <a target="_blank" href="https://www.olipes.com/eu/en/"><img   src={marca13cor} alt="" /></a>
        </div>
        <div className="col-14 w-64 mx-4 self-center flex-none">
        <a target="_blank" href="https://www.rodcraft.com/"> <img  src={marca14cor} alt="" /></a>
      
        </div>
        <div className="col-15 w-64 mx-4 self-center flex-none">
        <a target="_blank" href="https://www.scandinavian-tool.se/"><img src={marca15cor} alt="" /></a>
        </div>
        <div className="col-16 w-64 mx-4 self-center flex-none">
        <a target="_blank" href="https://www.somta.co.za/"> <img src={marca16cor} alt="" /></a>
        </div>
        <div className="col-17 w-64 mx-4 self-center flex-none">
        <a target="_blank" href="https://www.tyrolit.com/"> <img src={marca17cor} alt="" /></a>
        </div>
        <div className="col-18 w-64 mx-4 self-center flex-none">
        <a target="_blank" href="https://voelkel.com/en/"> <img  src={marca18branca} alt="" /></a>
        </div>
        <div className="col-19 w-64 mx-4 self-center flex-none">
        <a target="_blank" href="https://www.wikussawtech.com/en/ ">   <img  src={marca19cor} alt="" /></a>
        </div>
        <div className="col-19 w-64 mx-4 self-center flex-none">
        <a target="_blank" href="https://www.zmmbulgaria.com/en/ ">    <img  src={marca20cor} alt="" /></a>
        </div>
       
{/*      
        <div className="col-2 w-64 mx-4 self-center flex-none">
        <img src={marca2branca} alt="" />
        </div>
        <div className="col-3 w-64 mx-4 self-center flex-none">
        <img src={marca3branca} alt="" />
        </div>
        <div className="col-4 w-64 mx-4 self-center flex-none">
        <img src={marca4branca} alt="" />
        </div>
        <div className="col-5 w-64 mx-4 self-center flex-none">
        <img src={marca5branca} alt="" />
        </div>
        <div className="col-6 w-64 mx-4 self-center flex-none">
        <img src={marca6cor} alt="" />
        </div>
        <div className="col-7 w-64 mx-4 self-center flex-none">
        <img src={marca7branca} alt="" />
        </div>
        <div className="col-1 w-64 mx-4 self-center flex-none">
      <img src={marca1branca} alt="" />
        </div>
        <div className="col-8 w-64 mx-4 self-center flex-none">
        <img src={marca8cor} alt="" />
        </div>
        <div className="col-9 w-64 mx-4 self-center flex-none">
        <img src={marca9cor} alt="" />
        </div>
        <div className="col-10 w-64 mx-4 self-center flex-none">
        <img src={marca10cor} alt="" />
        </div>
        <div className="col-11 w-64 mx-4 self-center flex-none">
        <img src={marca11cor} alt="" />
        </div>
        <div className="col-12 w-64 mx-4 self-center flex-none">
        <img src={marca12branca} alt="" />
        </div>
        <div className="col-13 w-64 mx-4 self-center flex-none">
        <img src={marca13cor} alt="" />
        </div>
        <div className="col-14 w-64 mx-4 self-center flex-none">
        <img src={marca14cor} alt="" />
        </div>
        <div className="col-15 w-64 mx-4 self-center flex-none">
        <img src={marca15cor} alt="" />
        </div>
        <div className="col-16 w-64 mx-4 self-center flex-none">
        <img src={marca16cor} alt="" />
        </div>
        <div className="col-17 w-64 mx-4 self-center flex-none">
        <img src={marca17cor} alt="" />
        </div>
        <div className="col-18 w-64 mx-4 self-center flex-none">
        <img src={marca18branca} alt="" />
        </div>
        <div className="col-19 w-64 mx-4 self-center flex-none">
        <img src={marca19cor} alt="" />
        </div>
        <div className="col-19 w-64 mx-4 self-center flex-none">
            <img  src={marca20cor} alt="" />
        </div> */}
       
</Slider>
    </div>
   
  </div>
  <div id="section-brands-mobile">
 
 
    
  
 <div className="container2">

<Slider {...settingsMobile}>
   {/* <div className="col-2 w-64 mx-4 self-center flex-none">
       <img src={marca2branca} alt="" />
   
   </div>
   <div className="col-3 w-64 mx-4 self-center flex-none">
       <img   src={marca3branca} alt="" />
   </div>
   <div className="col-4 w-64 mx-4 self-center flex-none">
   
       <img  src={marca4branca} alt="" />
   </div>
   <div className="col-5 w-64 mx-4 self-center flex-none">
       <img  src={marca5branca} alt="" />
   </div>
   <div className="col-6 w-64 mx-4 self-center flex-none">
       <img  src={marca6cor} alt="" />
   </div>
   <div className="col-7 w-64 mx-4 self-center flex-none">
       <img src={marca7branca} alt="" />
   
   </div>
   <div className=" w-64 mx-4 self-center flex-none">
       <img src={marca1branca} alt="" />
  


   </div>
   <div className="col-8 w-64 mx-4 self-center flex-none">
       <img  src={marca8cor} alt="" />
   </div>
   <div className="col-9 w-64 mx-4 self-center flex-none">
       <img  src={marca9cor} alt="" />
  
   </div>
   <div className="col-10 w-64 mx-4 self-center flex-none">
       <img  src={marca10cor} alt="" />
   </div>
   <div className="col-11 w-64 mx-4 self-center flex-none">
       <img  src={marca11cor} alt="" />
   </div>
   
   <div className="col-12 w-64 mx-4 self-center flex-none">
       <img src={marca12branca} alt="" />
   </div>
   <div className="col-13 w-64 mx-4 self-center flex-none">
       <img   src={marca13cor} alt="" />
   </div>
   <div className="col-14 w-64 mx-4 self-center flex-none">
       <img  src={marca14cor} alt="" />
 
   </div>
   <div className="col-15 w-64 mx-4 self-center flex-none">
       <img src={marca15cor} alt="" />
   </div>
   <div className="col-16 w-64 mx-4 self-center flex-none">
       <img src={marca16cor} alt="" />
   </div>
   <div className="col-17 w-64 mx-4 self-center flex-none">
       <img src={marca17cor} alt="" />
   </div>
   <div className="col-18 w-64 mx-4 self-center flex-none">
       <img  src={marca18branca} alt="" />
   </div>
   <div className="col-19 w-64 mx-4 self-center flex-none">
       <img  src={marca19cor} alt="" />
   </div>
   <div className="col-19 w-64 mx-4 self-center flex-none">
       <img  src={marca20cor} alt="" />
   </div>
  

   <div className="col-2 w-64 mx-4 self-center flex-none">
   <img src={marca2branca} alt="" />
   </div>
   <div className="col-3 w-64 mx-4 self-center flex-none">
   <img src={marca3branca} alt="" />
   </div>
   <div className="col-4 w-64 mx-4 self-center flex-none">
   <img src={marca4branca} alt="" />
   </div>
   <div className="col-5 w-64 mx-4 self-center flex-none">
   <img src={marca5branca} alt="" />
   </div>
   <div className="col-6 w-64 mx-4 self-center flex-none">
   <img src={marca6cor} alt="" />
   </div>
   <div className="col-7 w-64 mx-4 self-center flex-none">
   <img src={marca7branca} alt="" />
   </div>
   <div className="col-1 w-64 mx-4 self-center flex-none">
 <img src={marca1branca} alt="" />
   </div>
   <div className="col-8 w-64 mx-4 self-center flex-none">
   <img src={marca8cor} alt="" />
   </div>
   <div className="col-9 w-64 mx-4 self-center flex-none">
   <img src={marca9cor} alt="" />
   </div>
   <div className="col-10 w-64 mx-4 self-center flex-none">
   <img src={marca10cor} alt="" />
   </div>
   <div className="col-11 w-64 mx-4 self-center flex-none">
   <img src={marca11cor} alt="" />
   </div>
   <div className="col-12 w-64 mx-4 self-center flex-none">
   <img src={marca12branca} alt="" />
   </div>
   <div className="col-13 w-64 mx-4 self-center flex-none">
   <img src={marca13cor} alt="" />
   </div>
   <div className="col-14 w-64 mx-4 self-center flex-none">
   <img src={marca14cor} alt="" />
   </div>
   <div className="col-15 w-64 mx-4 self-center flex-none">
   <img src={marca15cor} alt="" />
   </div>
   <div className="col-16 w-64 mx-4 self-center flex-none">
   <img src={marca16cor} alt="" />
   </div>
   <div className="col-17 w-64 mx-4 self-center flex-none">
   <img src={marca17cor} alt="" />
   </div>
   <div className="col-18 w-64 mx-4 self-center flex-none">
   <img src={marca18branca} alt="" />
   </div>
   <div className="col-19 w-64 mx-4 self-center flex-none">
   <img src={marca19cor} alt="" />
   </div>
   <div className="col-19 w-64 mx-4 self-center flex-none">
       <img  src={marca20cor} alt="" />
   </div> */}

<div className="col-2 w-64  self-center flex-none">
            <a target="_blank" href="https://www.dormerpramet.com/"><img src={marca2branca} alt="" /></a>
        
        </div>
        <div className="col-3 w-64  self-center flex-none">
        <a target="_blank" href="https://www.electrexwelding.com/"> <img   src={marca3branca} alt="" /></a>
        </div>
        <div className="col-4 w-64  self-center flex-none">
        
        <a target="_blank" href="https://www.facom.com/pt/">   <img  src={marca4branca} alt="" /></a>
        </div>
        <div className="col-5 w-64 self-center flex-none">
        <a target="_blank" href="https://www.guhring.com/">   <img  src={marca5branca} alt="" /></a>
        </div>
        <div className="col-6 w-64  self-center flex-none">
        <a target="_blank" href=" https://www.hazet.de/en/">  <img  src={marca6cor} alt="" /></a>
        </div>
        <div className="col-7 w-64 self-center flex-none">
        <a target="_blank" href=" https://www.innotool.de/">  <img src={marca7branca} alt="" /></a>
        
        </div>
        <div className=" w-64  self-center flex-none">
        <a target="_blank" href=" https://www.iscar.com/">   <img src={marca1branca} alt="" /> </a>
       
   

        </div>
        <div className="col-8 w-64  self-center flex-none">
        <a target="_blank" href="https://www.izartool.com/en/">  <img  src={marca8cor} alt="" /> </a>
        </div>
        <div className="col-9 w-64  self-center flex-none">
        <a target="_blank" href="https://www.masterprof.pt/pt/">   <img  src={marca9cor} alt="" /></a>
       
        </div>
        <div className="col-10 w-64  self-center flex-none">
        <a target="_blank" href="https://www.mitutoyo.com/"><img  src={marca10cor} alt="" /></a>
        </div>
        <div className="col-11 w-64 self-center flex-none">
        <a target="_blank" href="https://www.molyslip.com/"> <img  src={marca11cor} alt="" /></a>
        </div>
        
        <div className="col-12 w-64  self-center flex-none">
        <a target="_blank" href="https://www.nsk.com/"><img src={marca12branca} alt="" /></a>
        </div>
        <div className="col-13 w-64  self-center flex-none">
        <a target="_blank" href="https://www.olipes.com/eu/en/"><img   src={marca13cor} alt="" /></a>
        </div>
        <div className="col-14 w-64  self-center flex-none">
        <a target="_blank" href="https://www.rodcraft.com/"> <img  src={marca14cor} alt="" /></a>
      
        </div>
        <div className="col-15 w-64  self-center flex-none">
        <a target="_blank" href="https://www.scandinavian-tool.se/"><img src={marca15cor} alt="" /></a>
        </div>
        <div className="col-16 w-64  self-center flex-none">
        <a target="_blank" href="https://www.somta.co.za/"> <img src={marca16cor} alt="" /></a>
        </div>
        <div className="col-17 w-64  self-center flex-none">
        <a target="_blank" href="https://www.tyrolit.com/"> <img src={marca17cor} alt="" /></a>
        </div>
        <div className="col-18 w-64  self-center flex-none">
        <a target="_blank" href="https://voelkel.com/en/"> <img  src={marca18branca} alt="" /></a>
        </div>
        <div className="col-19 w-64  self-center flex-none">
        <a target="_blank" href="https://www.wikussawtech.com/en/ ">   <img  src={marca19cor} alt="" /></a>
        </div>
        <div className="col-19 w-64  self-center flex-none">
        <a target="_blank" href="https://www.zmmbulgaria.com/en/ ">    <img  src={marca20cor} alt="" /></a>
        </div>
  
</Slider>
</div>

</div>
  {/* <div id="section-brands-mobile">
  <Draggable
  axis="x">
       <div className="container">
    <div className="flex -mx-4 img-ticker">
   
      <div className="col-1 w-40 mx-4 self-center flex-none">
      <img src={marca2branca} alt="" />
        </div>
        <div className="col-2 w-40 mx-4 self-center flex-none">
        <img   src={marca3branca} alt="" />
        </div>
        <div className="col-3 w-40 mx-4 self-center flex-none">
        <img   src={marca4branca} alt="" />
        </div>
        <div className="col-4 w-40 mx-4 self-center flex-none">
        <img   src={marca5branca} alt="" />
        </div>
        <div className="col-5 w-40 mx-4 self-center flex-none">
        <img  src={marca6cor} alt="" />
        
        </div>
        <div className="col-6 w-40 mx-4 self-center flex-none">
        <img src={marca7branca} alt="" />
        </div>
        <div className="col-7 w-40 mx-4 self-center flex-none">
        <img src={marca1branca} alt="" />
        </div>
        <div className="col-8 w-40 mx-4 self-center flex-none">
        <img  src={marca8cor} alt="" />
        </div>
        <div className="col-9 w-40 mx-4 self-center flex-none">
        <img  src={marca9cor} alt="" />
        </div>
        <div className="col-10 w-40 mx-4 self-center flex-none">
        <img  src={marca10cor} alt="" />
        </div>
        <div className="col-11 w-40 mx-4 self-center flex-none">
        <img  src={marca11cor} alt="" />
        </div>
        <div className="col-12 w-40 mx-4 self-center flex-none">
        <img src={marca12branca} alt="" />
        </div>
        <div className="col-13 w-40 mx-4 self-center flex-none">
        <img  src={marca13cor} alt="" />
        </div>
        <div className="col-14 w-40 mx-4 self-center flex-none">
        <img  src={marca14cor} alt="" />
        </div>
        <div className="col-15 w-40 mx-4 self-center flex-none">
        <img  src={marca15cor} alt="" />
        </div>
        <div className="col-16 w-40 mx-4 self-center flex-none">
        <img  src={marca16cor} alt="" />
        </div>
        <div className="col-17 w-40 mx-4 self-center flex-none">
        <img  src={marca17cor} alt="" />
        </div>
        <div className="col-18 w-40 mx-4 self-center flex-none">
        <img src={marca18branca} alt="" />
        </div>
        <div className="col-19 w-40 mx-4 self-center flex-none">
        <img  src={marca19cor} alt="" />
        </div>
        <div className="col-19 w-40 mx-4 self-center flex-none">
        <img  src={marca20cor} alt="" />
        </div>
       
        <div className="col-1 w-40 mx-4 self-center flex-none">
      <img src={marca2branca} alt="" />
        </div>
        <div className="col-2 w-40 mx-4 self-center flex-none">
        <img   src={marca3branca} alt="" />
        </div>
        <div className="col-3 w-40 mx-4 self-center flex-none">
        <img   src={marca4branca} alt="" />
        </div>
        <div className="col-4 w-40 mx-4 self-center flex-none">
        <img   src={marca5branca} alt="" />
        </div>
        <div className="col-5 w-40 mx-4 self-center flex-none">
        <img  src={marca6cor} alt="" />
        
        </div>
        <div className="col-6 w-40 mx-4 self-center flex-none">
        <img src={marca7branca} alt="" />
        </div>
        <div className="col-7 w-40 mx-4 self-center flex-none">
        <img src={marca1branca} alt="" />
        </div>
        <div className="col-8 w-40 mx-4 self-center flex-none">
        <img  src={marca8cor} alt="" />
        </div>
        <div className="col-9 w-40 mx-4 self-center flex-none">
        <img  src={marca9cor} alt="" />
        </div>
        <div className="col-10 w-40 mx-4 self-center flex-none">
        <img  src={marca10cor} alt="" />
        </div>
        <div className="col-11 w-40 mx-4 self-center flex-none">
        <img  src={marca11cor} alt="" />
        </div>
        <div className="col-12 w-40 mx-4 self-center flex-none">
        <img src={marca12branca} alt="" />
        </div>
        <div className="col-13 w-40 mx-4 self-center flex-none">
        <img  src={marca13cor} alt="" />
        </div>
        <div className="col-14 w-40 mx-4 self-center flex-none">
        <img  src={marca14cor} alt="" />
        </div>
        <div className="col-15 w-40 mx-4 self-center flex-none">
        <img  src={marca15cor} alt="" />
        </div>
        <div className="col-16 w-40 mx-4 self-center flex-none">
        <img  src={marca16cor} alt="" />
        </div>
        <div className="col-17 w-40 mx-4 self-center flex-none">
        <img  src={marca17cor} alt="" />
        </div>
        <div className="col-18 w-40 mx-4 self-center flex-none">
        <img src={marca18branca} alt="" />
        </div>
        <div className="col-19 w-40 mx-4 self-center flex-none">
        <img  src={marca19cor} alt="" />
        </div>
        <div className="col-19 w-40 mx-4 self-center flex-none">
        <img  src={marca20cor} alt="" />
        </div>
       

    </div>
    </div>
    </Draggable>

  </div> */}
  </Layout>
)

export default IndexPage
